<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Team" subtitle="Manage the people that can also manage some or all aspects of the convention.">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no team members found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.user.name" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto 24px',
			endpoint: 'convention/team/members',
			live: 'convention/team/members',
			edit: 'Convention.Team.Members.Edit'
		}

	}

}

</script>

<style scoped>

</style>